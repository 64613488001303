.rightbarContent {
  padding: 25px;
  width: 80%;
}

.trends {
  border-radius: 25px;
  min-height: calc(100vh - 200px);
  max-width:290px;
  margin-top: 40px;
  padding: 20px;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
}

.trend{
    display:flex;
    justify-content: start;
    align-items: center;
    gap:20px;
    margin-top: 15px;
    padding: 10px;
}

.trend:hover{
    cursor: pointer;
    background-color: #2c353e;
}

.trendImg{
    width: 100px;
    border-radius: 3px;
}

.trendText {
    font-size: 15px;
    font-weight: normal;
}

.more {
    font-size: 15px;
    font-weight: normal;
    padding: 15px;
    color:#1DA1F2;
}

.notcopy {
    color: white;
    position: relative;
    margin-right: auto; 
    margin-left: 100px;
    margin-top: 40px;
}
