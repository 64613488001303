.profileBanner {
    width: 100%;
    max-width: 100%;
    height: 120px;
    object-fit: cover;
}

.pfpContainer {
    max-width:100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    border-bottom: 1px solid rgb(63, 63, 63);
}

.profilePFP {
    width: 80px;
    border-radius: 50%;
    border: 5px solid #141d26;
    position: relative;
    top:-55px;
    left:20px;
}

.profileName {
    color: white;
    position: relative;
    top:-55px;
    left:20px;
    font-size: 20px;
    font-weight: bold;
}

.profileWallet {
    color: rgb(114, 114, 114);
    position: relative;
    top:-50px;
    left:20px;
    font-size: 14px;
}

.profileBio {
    color: white;
    position: relative;
    top:-40px;
    left:20px;
    font-size: 16px;
    font-weight: 500;
}

.profileEdit {
    color: white;
    position: relative;
    top:-120px;
    margin-left: auto; 
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    border: 0.5px solid white;
    width: 96px;
    justify-content: center;
    display:flex;
    padding: 8px 8px;
    border-radius: 1000px;
}

.profileEdit:hover {
    cursor: pointer;
}

.profileTabs {
    display: flex;
    justify-content: center;
    color: white;
    font-weight: bold;
}

.profileTab {
    padding-bottom: 10px;  
    margin: 0 10px;
    cursor: pointer;
}

.profileTab.active {
    border-bottom: 2px solid #1DA1F2;
}