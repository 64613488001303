.settingsPage {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.pfp {
    color:#68738D;
    padding-left: 10px;
}

.pfpOptions {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 10px;
    gap:30px;
}

.pfpOption {
    border-radius: 50%;
    margin-top:10px;
    width:110px;
    height:110px;
    border:5px;
}

.pfpOption {
    cursor: pointer;
}

.pfpOptionSelected {
    border-radius: 50%;
    margin-top:10px;
    width:110px;
    height:110px;
    border:5px solid #7db434;
}

.banner {
    width:90%;
    height:200px;
    object-fit: cover;
}

.banner:hover {
    cursor:pointer;
}

.save {
    background-color: #7db434;
    padding: 10px 20px;
    border-radius: 1000px;
    color: white;
    font-weight: 600;
    width: 50px;
    display: flex;
    justify-content: center;
    margin-left: 70%;
}

.save:hover{
    cursor:pointer;
}